import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FILTERED_MEMBERS,
  CREATE_FAMILY_VARISANGYA_WALLET,
  GET_FAMILY_VARISANGYA_WALLET_DETAIL,
  GET_FAMILY_VARISANGYA_WALLET_VIEW,
  DELETE_FAMILY_VARISANGYA_WALLET,
  UPDATE_FILTERED_WALLETS,
  GET_VARISANGYA_WALLET_DETAIL,
  CREATE_FAMILY_VARISANGYA_WALLET_NEW,
  GET_ALL_FAMILY_VARISANGYA_WALLET,
  GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW,
  DELETE_VARISANGYA_WALLET,
  GET_VARISANGYA_TRANSACTION_DETAIL,
  UPDATE_SELECTED_WALLETS,
} from "./actionTypes"
import {
  getFamilyVarisangyaWalletsSuccess,
  getFamilyVarisangyaWalletsFail,
  createFamilyVarisangyaWalletSuccess,
  createFamilyVarisangyaWalletFail,
  getVarisangyaWalletDetailSuccess,
  getFamilyVarisangyaWalletDetailFail,
  getFamilyVarisangyaWalletViewSuccess,
  getFamilyVarisangyaWalletViewFail,
  deleteFamilyVarisangyaWalletSuccess,
  deleteFamilyVarisangyaWalletFail,
  getVarisangyaWalletsSuccess,
  getVarisangyaWalletsFail,
  getFilteredMembersSuccess,
  getFilteredMembersFail,
  updateFilteredWalletsSuccess,
  updateFilteredWalletsFail,
  getFilteredMembers,
  getVarisangyaWalletDetailFail,
  createFamilyVarisangyaWalletNewSuccess,
  createFamilyVarisangyaWalletNewFail,
  getAllFamilyVarisangyaWalletSuccess,
  getAllFamilyVarisangyaWalletFail,
  getAllFamilyVarisangyaWallet,
  getAllFamilyMemberVarisangyaWalletSuccess,
  getAllFamilyMemberVarisangyaWalletFail,
  createFamilyMemberVarisangyaWalletNewSuccess,
  createFamilyMemberVarisangyaWalletNewFail,
  getAllFamilyMemberVarisangyaWallet,
  deleteVarisangyaWalletSuccess,
  deleteVarisangyaWalletFail,
  getVarisangyaTransactionDetailsSuccess,
  getVarisangyaTransactionDetailFail,
  getVarisangyaWalletDetail,
  updateSelectedWalletsFail,
} from "./actions"

import { get, post, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { getFamilyMemberDetail } from "store/actions"

const getVarisangyaWalletsAPi = ({
  searchText,
  page,
  sort,
  type,
  varisangyaId,
}) => {
  if (sort === "manual" && type === "family") {
    return get(
      `/api/v2/varisankya/mahall-user/varisankya-family-wallet/?search=${
        searchText && searchText
      }&page=${page ? page : 1}&is_manual=True&varisangya=${varisangyaId}`
    )
  } else {
    return get(
      `/api/v2/varisankya/mahall-user/varisankya-family-member-wallet/?search=${
        searchText && searchText
      }&page=${page ? page : 1}&varisangya=${varisangyaId}`
    )
  }
}
const updateVarisangyaWalletApi = ({ filteredVarisangyaWallet, type }) => {
  if (type === "family") {
    return post(
      `api/v2/varisankya/mahall-user/update-family-wallet/
      `,
      filteredVarisangyaWallet
    )
  } else {
    return post(
      `/api/v2/varisankya/mahall-user/update-family-member-wallet/
      `,
      filteredVarisangyaWallet
    )
  }
}

const updateVarisangyaSelectedWalletsApi = ({ listdata, type,varisangyaId,amount }) => {
  if (type === "family") {
    return post(
      `api/v2/varisankya/mahall-user/update-selected-family-wallet/
      `,
      {listdata,varisangyaId,amount}
    )
  } else {
    return post(
      `/api/v2/varisankya/mahall-user/update-selected-family-member-wallet/
      `,
      {listdata,varisangyaId,amount}
    )
  }
}


const getFilteredMemberAPi = ({
  varisangyaId,
  searchText,
  pageSize,
  page,
  filterData,
  type,
}) => {
  if (type === "family") {
    console.log("page =>", page)

    return post(
      `/api/v2/filter/mahall-user/family-filter/?search=${
        searchText && searchText
      }&page=${page || 1}&page_size=${pageSize || 10}`,
      filterData
    )
  } else {
    return post(
      `/api/v2/filter/mahall-user/family-member-filter/?search=${
        searchText && searchText
      }&page=${page || 1}&page_size=${pageSize || 10}`,
      filterData
    )
  }
}

const getVarisangyaWalletDetailAPi = ({ walletId, type }) => {
  if (type === "family") {
    return get(
      `/api/v2/varisankya/mahall-user/varisankya-family-wallet/${walletId}`
    )
  } else {
    return get(
      `/api/v2/varisankya/mahall-user/varisankya-family-member-wallet/${walletId}`
    )
  }
}

const getVarisangyaTransactionDetailAPi = ({ transactionId, type }) => {
  if (type === "family") {
    return get(
      `/api/v2/varisankya/mahall-user/varisankya-family-transaction/${transactionId}`
    )
  } else {
    return get(
      `/api/v2/varisankya/mahall-user/varisankya-family-member-transaction/${transactionId}`
    )
  }
}

const getAllFamilyVarisangyaWalletAPi = ({
  page,
  limit,
  search,
  walletId,
  varinsangyaId,
  familyId,
}) => {
  return get(
    `/api/v2/varisankya/mahall-user/varisankya-family-transaction/?page=${
      page || 1
    }&search=${search || ""}&page_send=${
      limit || ""
    }&varisankya_family_wallet=${walletId}&varisankya=${
      varinsangyaId || ""
    }&family=${familyId || ""}`
  )
}

const getAllFamilyMemberVarisangyaWalletAPi = ({
  page,
  limit,
  search,
  walletId,
  varisangyaId,
  familyMemberId,
}) => {
  console.log("varisangyaId =>", varisangyaId)
  return get(
    `/api/v2/varisankya/mahall-user/varisankya-family-member-transaction/?page=${
      page || 1
    }&search=${search || ""}&page_send=${
      limit || ""
    }&varisankya_family_member_wallet=${walletId}&varisankya=${
      varisangyaId || ""
    }&family_member=${familyMemberId || ""}`
  )
}

const deleteVarisangyaWalletApi = ({ transactionId, type }) => {
  if (type === "family") {
    return del(
      `/api/v2/varisankya/mahall-user/varisankya-family-transaction/${transactionId}/
      `
    )
  } else {
    return del(
      `/api/v2/varisankya/mahall-user/varisankya-family-member-transaction/${transactionId}/
      `
    )
  }
}

const createFamilyVarisangyaWalletApiNew = ({ familyVarisangyaWallet }) => {
  console.log("familyVarisangyaWallet =>", familyVarisangyaWallet)
  return post(
    "/api/v2/varisankya/mahall-user/varisankya-family-transaction/",
    familyVarisangyaWallet
  )
}

const createFamilyMemberVarisangyaWalletApiNew = ({
  familyMemberVarisangyaWallet,
}) => {
  console.log("familyMemberVarisangyaWallet =>", familyMemberVarisangyaWallet)

  return post(
    "/api/v2/varisankya/mahall-user/varisankya-family-member-transaction/",
    familyMemberVarisangyaWallet
  )
}

// function* fetchVarisangyaWallets({ payload }) {
//   try {
//     const response = yield call(getVarisangyaWalletsAPi, payload)
//     if (response && !response?.error) {
//       yield put(getVarisangyaWalletsSuccess(response))
//     } else {
//       yield put(getVarisangyaWalletsFail(response))
//       errorNotification()
//     }
//   } catch (error) {
//     yield put(getVarisangyaWalletsFail(error))
//   }
// }

function* fetchFilteredMembers({ payload }) {
  try {
    const response = yield call(getFilteredMemberAPi, payload)
    if (response && !response?.error) {
      yield put(getFilteredMembersSuccess(response))
    } else {
      yield put(getFilteredMembersFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFilteredMembersFail(error))
  }
}

function* onCreateFamilyVarisangyaWallet({ payload }) {
  console.log("payload =>", payload)

  try {
    const response = yield call(createFamilyVarisangyaWalletApiNew, payload)
    if (response && !response?.error) {
      yield put(createFamilyVarisangyaWalletNewSuccess(response))
      if (payload?.page === "wallet") {
        yield put(
          getVarisangyaWalletDetail(
            payload?.familyVarisangyaWallet?.varisankya_family_wallet,
            "family"
          )
        )
      }
      doneNotification("Created Successfully")
      if (payload?.handleReset) {
        payload.handleReset()
      }
      if (payload?.page === "wallet") {
        yield put(
          getAllFamilyVarisangyaWallet(
            "",
            "",
            "",
            payload?.familyVarisangyaWallet?.varisankya_family_wallet,
            payload?.familyVarisangyaWallet?.varinsangya,
            payload?.familyVarisangyaWallet?.family
          )
        )
      } else {
        yield put(
          getAllFamilyVarisangyaWallet(
            "",
            "",
            "",
            "",
            payload?.familyVarisangyaWallet?.varisankya,
            payload?.familyVarisangyaWallet?.family
          )
        )
      }
    } else {
      yield put(createFamilyVarisangyaWalletNewFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited" ,
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createFamilyVarisangyaWalletNewFail(error))
    errorNotification()
  }
}

function* onCreateFamilyMemberVarisangyaWallet({ payload }) {
  console.log("payload =>", payload)

  try {
    const response = yield call(
      createFamilyMemberVarisangyaWalletApiNew,
      payload
    )
    if (response && !response?.error) {
      yield put(createFamilyMemberVarisangyaWalletNewSuccess(response))
      doneNotification("Created Successfully")
      if (payload?.handleReset) {
        payload.handleReset()
      }
      if (payload?.page === "wallet") {
        yield put(
          getVarisangyaWalletDetail(
            payload.familyMemberVarisangyaWallet
              ?.varisankya_family_member_wallet,
            "member"
          )
        )
      }
      if (payload?.formRef) {
        formRef.current.reset()
      }
      yield put(
        getAllFamilyMemberVarisangyaWallet(
          // "",
          // "",
          // "",
          // payload?.familyMemberVarisangyaWallet?.varisankya,
          // payload?.familyMemberVarisangyaWallet?.familyMemberId
          "",
          "",
          "",
          payload?.familyMemberVarisangyaWallet
            ?.varisankya_family_member_wallet,
          payload?.familyMemberVarisangyaWallet?.varisankya,
          payload?.familyMemberVarisangyaWallet?.family_member
        )
      )
    } else {
      yield put(createFamilyMemberVarisangyaWalletNewFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited" ,
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    console.log("error: ", error)
    yield put(createFamilyMemberVarisangyaWalletNewFail(error))
    errorNotification()
  }
}

function* onUpdateVarisangyaWallet({ payload }) {
  console.log(payload)

  try {
    const response = yield call(updateVarisangyaWalletApi, payload)
    if (response && !response?.error) {
      yield put(updateFilteredWalletsSuccess(response))
      doneNotification("Updated Successfully")
      getFilteredMembersSuccess
      yield put(
        getFilteredMembers(
          payload?.filteredVarisangyaWallet?.varisankya_id,
          "",
          "",
          "",
          payload?.filteredVarisangyaWallet,
          payload?.type
        )
      )
    } else {
      yield put(updateFilteredWalletsFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(updateFilteredWalletsFail(error))
    errorNotification()
  }
}



function* onUpdateVarisangyaSelectedWallets({ payload }) {
  console.log(payload)

  try {
    const response = yield call(updateVarisangyaSelectedWalletsApi, payload)
    if (response && !response?.error) {
      yield put(updateFilteredWalletsSuccess(response))
      doneNotification("Updated Successfully")
      window.location.reload(false)
      // yield put(
      //   getFilteredMembers(
      //     payload?.varisankya_id,
      //     "",
      //     "",
      //     "",
      //     {"sort":["-custom_id"],"varisankya_id":payload?.varisnakyaId},
      //     payload?.type
      //   )
      // )
      // yield put(
      //   getFilteredMembers(
      //     payload?.varisankya_id,
      //     "",
      //     "",
      //     "",
      //     {},
      //     payload?.type
      //   )
      // )
    } else {
      yield put(updateSelectedWalletsFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(updateFilteredWalletsFail(error))
    errorNotification()
  }
}

function* fetchVarisangyaWalletDetail({ payload }) {
  try {
    const response = yield call(getVarisangyaWalletDetailAPi, payload)
    if (response && !response?.error) {
      yield put(getVarisangyaWalletDetailSuccess(response))
    } else {
      yield put(getVarisangyaWalletDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getVarisangyaWalletDetailFail(error))
  }
}

function* fetchVarisangyaTransactionDetail({ payload }) {
  try {
    const response = yield call(getVarisangyaTransactionDetailAPi, payload)
    if (response && !response?.error) {
      yield put(getVarisangyaTransactionDetailsSuccess(response))
    } else {
      yield put(getVarisangyaTransactionDetailsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getVarisangyaTransactionDetailFail(error))
  }
}

function* fetchAllFamilyVarisangyaWallet({ payload }) {
  try {
    const response = yield call(getAllFamilyVarisangyaWalletAPi, payload)
    if (response && !response?.error) {
      yield put(getAllFamilyVarisangyaWalletSuccess(response))
    } else {
      yield put(getAllFamilyVarisangyaWalletFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAllFamilyVarisangyaWalletFail(error))
  }
}

function* fetchAllFamilyMemberVarisangyaWallet({ payload }) {
  console.log("payload =>", payload)

  try {
    const response = yield call(getAllFamilyMemberVarisangyaWalletAPi, payload)
    if (response && !response?.error) {
      yield put(getAllFamilyMemberVarisangyaWalletSuccess(response))
    } else {
      yield put(getAllFamilyMemberVarisangyaWalletFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAllFamilyMemberVarisangyaWalletFail(error))
  }
}
function* deleteVarisangyaWallet({ payload }) {
  try {
    const response = yield call(deleteVarisangyaWalletApi, payload)
    if (response && !response?.error) {
      doneNotification("Transaction Removed Successfully")
      yield put(deleteVarisangyaWalletSuccess(response))
      if (payload?.type === "family") {
        yield put(
          getAllFamilyVarisangyaWallet(
            "",
            "",
            "",
            "",
            payload?.varisnakyaId,
            payload?.memberId
          )
        )
      } else {
        yield put(
          getAllFamilyMemberVarisangyaWallet(
            "",
            "",
            "",
            "",
            payload?.varisnakyaId,
            payload?.memberId
          )
        )
      }
      if (familyDetailsResponse) {
        yield put(getVarisangyaWalletDetailSuccess(familyDetailsResponse))
      }
    } else {
      yield put(deleteVarisangyaWalletFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(deleteVarisangyaWalletFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* VarisangyaWalletsSaga() {
  // yield takeEvery(GET_FILTERED_MEMBERS, fetchVarisangyaWallets)

  yield takeEvery(
    CREATE_FAMILY_VARISANGYA_WALLET_NEW,
    onCreateFamilyVarisangyaWallet
  )
  yield takeEvery(
    CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW,
    onCreateFamilyMemberVarisangyaWallet
  )
  yield takeEvery(GET_FILTERED_MEMBERS, fetchFilteredMembers)
  yield takeEvery(UPDATE_FILTERED_WALLETS, onUpdateVarisangyaWallet)
  yield takeEvery(UPDATE_SELECTED_WALLETS, onUpdateVarisangyaSelectedWallets)
  yield takeEvery(GET_VARISANGYA_WALLET_DETAIL, fetchVarisangyaWalletDetail)
  yield takeEvery(
    GET_VARISANGYA_TRANSACTION_DETAIL,
    fetchVarisangyaTransactionDetail
  )
  yield takeEvery(
    GET_ALL_FAMILY_VARISANGYA_WALLET,
    fetchAllFamilyVarisangyaWallet
  )

  yield takeEvery(
    GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET,
    fetchAllFamilyMemberVarisangyaWallet
  )
  yield takeEvery(DELETE_VARISANGYA_WALLET, deleteVarisangyaWallet)
}

export default VarisangyaWalletsSaga
