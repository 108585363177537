import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label } from "reactstrap"

import {
  createInstituteWallet,
  getInstituteFinancialYears,
} from "store/actions"

const UiModalFinancialYearUpdate = ({ show, onCloseclick }) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const { loading, language, instituteId, instituteFinancialYears } =
    useSelector(state => ({
      loading: state.Assets.loading,
      language: state.Layout.language,
      instituteId: state.Institutes.instituteMahallDetail?.id,
      instituteFinancialYears: state.Institutes.institute_financial_years,
    }))

  const handleValidSubmit = (onSubmitProps, values) => {
    let year = instituteFinancialYears?.financial_years.find(
      year => year.id == values.financial_year
    )
    localStorage.setItem("financial_year_starting_date", year.starting_date)
    localStorage.setItem("financial_year_closing_date", year.closing_date)
    onCloseclick()

    // dispatch(createInstituteWallet(createInstituteWalletData))
  }
  useEffect(() => {
    dispatch(getInstituteFinancialYears())
  }, [])

  // console.log("instituteFinancialYears?.financial_years");
  // console.log(instituteFinancialYears?.financial_years);

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal"
                ? "സാമ്പത്തിക വർഷം മാറ്റുക"
                : "Change Financial Year"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "സാമ്പത്തിക വർഷം" : "Financial Year"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    type="select"
                    name="financial_year"
                    validate={{
                      required: { value: true },
                    }}
                  >
                    <option value="">
                      {language === "mal" ? "തിരഞ്ഞെടുക്കുക" : "Select"}
                    </option>
                    {instituteFinancialYears?.financial_years?.map(
                      (year, index) => (
                        <option key={index} value={year.id}>
                          {year.starting_date} to {year.closing_date}
                        </option>
                      )
                    )}
                  </AvField>
                </Col>
              </div>

              <div className="row w-100 justify-content-end">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalFinancialYearUpdate
UiModalFinancialYearUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}
