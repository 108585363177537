import React from "react"
import { Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useWindowSize } from "hooks/useWindowSize"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import Footer from "pages/common/LandingPage/Footer/footer"
import logodark from "assets/images/logo/logo.png"
function PrivacyPolicy() {
  const isMobile = useWindowSize()
  return (
    <React.Fragment>
      <div className="account-pages">
        <MetaTags>
          <title>Privacy | Khaf </title>
        </MetaTags>
        <Container>
          <div className="d-flex justify-content-between my-5">
            <img src={logodark} alt="" height="40" className="logo logo-dark" />
            <div>
              <Link to="/home/ml" className="btn btn-outline-primary w-xs me-2">
                Home
              </Link>
              <Link to="/login" className="btn btn-success w-xs bg-khaf">
                Login
              </Link>
            </div>
          </div>
          <p className="fw-bold" style={{ fontSize: "40px" }}>
            Privacy Policy
          </p>
          <h6 className="privacy-black">Khaf Smart Mahal</h6>
          <h6 className="pt-3 pb-5 text-black">Last updated on: 19-03-2024</h6>
          <p className="text-black fs-5 m-0">
            <span>1.</span> <span className="fw-bold">Introduction</span>
          </p>
          <div className={isMobile > 475 ? "w-75" : "w-100"}>
            <p className="text-muted text-justify fs-5 pt-2">
              This document serves as the Privacy Policy for the{" "}
              <span className="fw-bold text-black">Khaf Smart Mahal</span>{" "}
              application, managed by{" "}
              <span className="fw-bold text-black">
                KHAF MAHALL SHAKTHEEKARANA SAMITHI
              </span>
              It details the collection, use, and protection of personal
              information of users and emphasizes our commitment to safeguarding
              privacy.
            </p>
          </div>
          <p className="text-black fs-5 m-0 pt-3">
            <span>2.</span>{" "}
            <span className="fw-bold">Information Collection</span>
          </p>
          <div className={isMobile > 475 ? "w-75" : "w-100"}>
            <p className="text-muted text-justify fs-5 pt-2">
              Khaf Smart Mahal serves as a centralized platform for Mahall
              (community) administrations to gather and manage their members
              data based on their needs. The process requires that the admin or
              an officially designated authority submit necessary documentation
              (referred to as the Documents) to verify their authorization to
              act on behalf of the Mahall Committee. This designated person is
              solely responsible for obtaining member consent for data input.
            </p>
            <p className="text-muted text-justify fs-5 pt-3">
              The Mahall group committee has the authority to request written
              consent from the admin or to mandate a registration process
              following their internal regulations. Khaf Smart Mahal maintains a
              neutral stance and does not interfere with the internal governance
              rules set by any Mahall committee or Mahall Group committee.
            </p>
            <p className="text-muted text-justify fs-5 pt-3">
              Support is offered to help with the application or utilization of
              services provided by Khaf Smart Mahal, but the reliability of the
              survey team and data management falls under the responsibility of
              the admin. The application facilitates communication between
              Mahall administrators and their members, enabling the distribution
              and receipt of notifications through the service.
            </p>
            <p className="text-muted text-justify fs-5 pt-3">
              Khaf Smart Mahal commits to protecting against online data theft
              from its servers. However, it excludes liability for data sharing
              actions taken by admins, or for the theft or compromise of login
              credentials and devices, highlighting the importance of secure
              data management practices by the admin.
            </p>
          </div>
          <p className="text-black fs-5 m-0 pt-3">
            <span>3.</span> <span className="fw-bold">Use of Information</span>
          </p>
          <div className={isMobile > 475 ? "w-75" : "w-100"}>
            <p className="text-muted text-justify fs-5 pt-2">
              The data collected by the Khaf Smart Mahal application is
              exclusively managed by the corresponding Mahall administrators
              (admin) or users (members) specifically authorized by them. This
              ensures that access to sensitive community information is tightly
              controlled and only available to designated individuals. These
              administrators have the ability to regulate, monitor, and use the
              collected data to facilitate community management, enhance
              decision-making processes, and improve service delivery within the
              Mahall. This structured access underscores the commitment to data
              privacy and security, aligning with the principles of responsible
              data management.
            </p>
          </div>
          <p className="text-black fs-5 m-0 pt-3">
            <span>4.</span>{" "}
            <span className="fw-bold">Data Sharing and Disclosure</span>
          </p>
          <div className={isMobile > 475 ? "w-75" : "w-100"}>
            <p className="text-muted text-justify fs-5 pt-2">
              Khaf Smart Mahal maintains strict confidentiality and does not
              share internal data externally. However, the platform is not
              responsible for any data that is made public by Mahal admins, its
              members, or other users. If these parties choose to share
              information publicly, they assume full responsibility for the
              consequences of such actions. This distinction ensures that Khaf
              Smart Mahal adheres to its commitment to data privacy while
              clarifying that the responsibility for managing publicly shared
              data lies with the individual users or admins.
            </p>
            <p className="text-muted text-justify fs-5 pt-3">
              While Khaf Smart Mahal prioritizes the privacy of internal data,
              there may be circumstances where information is shared with third
              parties. This sharing will be strictly for purposes of service
              improvement, legal compliance, or in response to legitimate
              government requests. In such cases, all third-party engagements
              will be conducted with the utmost respect for privacy and will be
              strictly governed by the terms of this policy, ensuring that data
              handling adheres to the established privacy and security
              standards.
            </p>
          </div>
          <p className="text-black fs-5 m-0 pt-3">
            <span>5.</span> <span className="fw-bold">Data Security</span>
          </p>
          <div className={isMobile > 475 ? "w-75" : "w-100"}>
            <p className="text-muted text-justify fs-5 pt-2">
              When creating an account with Khaf Smart Mahal, users are required
              to provide accurate, complete, and current information at all
              times. Failing to maintain the accuracy and completeness of
              account information is considered a violation of the Terms of Use
              and may lead to immediate account termination without notice
            </p>
            <p className="text-muted text-justify fs-5 pt-3">
              Account security is the users responsibility, including
              safeguarding the password or One-Time Password (OTP) used to
              access the service. Users are accountable for all activities
              conducted under their account credentials. It is imperative that
              users do not share their password or OTP with anyone and
              immediately report any security breaches or unauthorized account
              usage to the service provider
            </p>
            <p className="text-muted text-justify fs-5 pt-3">
              The choice of username or title must respect legal boundaries and
              propriety. Users are prohibited from selecting names that infringe
              on another persons or entitys rights, are lawfully protected, or
              are offensive, vulgar, or obscene. The intent is to maintain a
              respectful and secure online environment, ensuring that all
              interactions within the platform adhere to legal and ethical
              standards.
            </p>
          </div>
          <p className="text-black fs-5 m-0 pt-3">
            <span>6.</span> <span className="fw-bold">Data Retention</span>
          </p>
          <div className={isMobile > 475 ? "w-75" : "w-100"}>
            <p className="text-muted text-justify fs-5 pt-2">
              Khaf Smart Mahal retains data only for the period necessary to
              fulfill its service purposes, comply with legal obligations, and
              resolve disputes. The data retention period extends until the
              admin chooses to delete it, adhering to a clear data deletion
              policy. This policy ensures that data is not kept indefinitely and
              is deleted in a secure manner once its purpose has been served or
              upon the admins request, maintaining data minimization principles
              and ensuring compliance with applicable data protection
              regulations.
            </p>
            <p className="text-muted text-justify fs-5 pt-3">
              In Khaf Smart Mahal, admin data and Mahall primary data will be
              maintained until explicitly deleted by the authority, based on the
              authorized consent of the Mahal admin. This ensures that data
              handling complies with the specified administrative protocols and
              privacy standards. The process for data deletion is structured to
              uphold data protection principles, allowing for the removal of
              data when it is no longer needed for service purposes or upon the
              Mahal admins request, adhering to legal and regulatory
              requirements.
            </p>
          </div>
          <p className="text-black fs-5 m-0 pt-3">
            <span>7.</span> <span className="fw-bold">User Rights</span>
          </p>
          <div className={isMobile > 475 ? "w-75" : "w-100"}>
            <p className="text-muted text-justify fs-5 pt-2">
              Khaf Smart Mahal acknowledges the importance of user rights
              concerning personal data. Users have the right to access their
              data stored within the service. To update or delete this
              information, users need to contact their corresponding Mahall
              administrator, who has authorized access to manage such requests.
            </p>
            <p className="text-muted text-justify fs-5 pt-3">
              This process ensures that data management remains secure and
              controlled, with authorized administrators acting as the point of
              contact for any changes to personal data. This approach helps
              maintain the integrity and confidentiality of user information,
              aligning with data protection principles and regulations.
            </p>
            <p className="text-muted text-justify fs-5 pt-3">
              Khaf Smart Mahal acknowledges the importance of intellectual
              property rights and commits to addressing any claims of
              infringement. If an individual or entity believes that their
              copyrighted work has been unlawfully replicated within the
              Service, they are encouraged to file a written complaint to Khafs
              designated contact point for copyright infringement, detailing the
              specifics of the alleged violation.
            </p>
            <p className="text-muted text-justify fs-5 pt-3">
              The Service emphasizes that false claims of copyright infringement
              can lead to legal accountability, potentially resulting in the
              claimant bearing costs and attorneys fees. This policy underscores
              the Services dedication to lawful content use and the protection
              of intellectual property rights, ensuring a respectful and legally
              compliant digital environment.
            </p>
          </div>
          <p className="text-black fs-5 m-0 pt-3">
            <span>8.</span> <span className="fw-bold">Policy Updates</span>
          </p>
          <div className={isMobile > 475 ? "w-75" : "w-100"}>
            <p className="text-muted text-justify fs-5 pt-2">
              Khaf Smart Mahal reserves the right to update its privacy policy
              and terms of use at any time, with or without prior notice. Users
              are encouraged to review the policies periodically to stay
              informed about any changes. Updates may reflect changes in legal
              requirements, operational functions, or user feedback. The
              continuous use of the service after such updates constitutes
              acceptance of the new terms.
            </p>
          </div>
          <p className="text-black fs-5 m-0 pt-3">
            <span>9.</span>{" "}
            <span className="fw-bold">Limitation Of Liability</span>
          </p>
          <div className={isMobile > 475 ? "w-75" : "w-100"}>
            <p className="text-muted text-justify fs-5 pt-2">
              In no event shall KHAF MAHALL SHAKTHEEKARANA SAMITHI, including
              their directors, employees, or affiliates, be liable for any
              indirect, incidental, special, consequential, or punitive damages.
              This limitation includes losses of profits, data, use, goodwill,
              or other intangible losses that may arise from the use or
              inability to use the Khaf Smart Mahal Service, actions or content
              of third parties within the Service, content obtained through the
              Service, or unauthorized access, use, or alteration of user
              transmissions or content.
            </p>
            <p className="text-muted text-justify fs-5 pt-3">
              This limitation of liability applies to all claims under any legal
              theory, even if the organization has been advised of the
              possibility of such damages or if a remedy set forth herein is
              found to have failed of its essential purpose, ensuring the
              limitation of liability for KHAF MAHALL SHAKTHEEKARANA SAMITHI to
              the maximum extent permitted by law.
            </p>
          </div>
          <p className="text-black fs-5 m-0 pt-3">
            <span>10.</span>{" "}
            <span className="fw-bold">Contact Information</span>
          </p>
          <div className={isMobile > 475 ? "w-75" : "w-100"}>
            <p className="text-muted text-justify fs-5 pt-2">
              For privacy-related inquiries, users can contact via provided
              contact details.
            </p>
          </div>
          <div style={{ maxWidth: "600px" }} className="mt-5">
            <p className="text-black fs-4 m-0">
              <b>KHAF MAHALL SHAKTHEEKARANA SAMITHI</b>
            </p>
            <p className="text-black m-0 fs-5">
              Room: Z74, 3rd Floor Kepees Daliya Avenue
              <br />
              PMNA Road, Up-Hill, Malappuram
              <br />
              Pin-676505
            </p>
            <p className="m-0 pt-4 text-black">
              Contact Number: +91 6235666630
            </p>
            <p className="text-black">Email: support@khaf.in</p>
          </div>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default PrivacyPolicy
