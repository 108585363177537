/* INSTITUTES */
export const GET_INSTITUTES = "GET_INSTITUTES"
export const GET_INSTITUTES_SUCCESS = "GET_INSTITUTES_SUCCESS"
export const GET_INSTITUTES_FAIL = "GET_INSTITUTES_FAIL"

/* INSTITUTE_DROPDOWN DETAIL*/
export const GET_INSTITUTE_DROPDOWN = "GET_INSTITUTE_DROPDOWN"
export const GET_INSTITUTE_DROPDOWN_SUCCESS = "GET_INSTITUTE_DROPDOWN_SUCCESS"
export const GET_INSTITUTE_DROPDOWN_FAIL = "GET_INSTITUTE_DROPDOWN_FAIL"

/* INSTITUTES DETAIL*/
export const GET_INSTITUTE_DETAIL = "GET_INSTITUTE_DETAIL"
export const GET_INSTITUTE_DETAIL_SUCCESS = "GET_INSTITUTE_DETAIL_SUCCESS"
export const GET_INSTITUTE_DETAIL_FAIL = "GET_INSTITUTE_DETAIL_FAIL"

/* INSTITUTES DETAIL*/
export const GET_INSTITUTE_MAHALL_DETAIL = "GET_INSTITUTE_MAHALL_DETAIL"
export const GET_INSTITUTE_MAHALL_DETAIL_SUCCESS =
  "GET_INSTITUTE_MAHALL_DETAIL_SUCCESS"
export const GET_INSTITUTE_MAHALL_DETAIL_FAIL =
  "GET_INSTITUTE_MAHALL_DETAIL_FAIL"

/* INSTITUTE CARDS*/
export const GET_INSTITUTES_CARDS = "GET_INSTITUTES_CARDS"
export const GET_INSTITUTES_CARDS_SUCCESS = "GET_INSTITUTES_CARDS_SUCCESS"
export const GET_INSTITUTES_CARDS_FAIL = "GET_INSTITUTES_CARDS_FAIL"

/**
 * add INSTITUTE
 */
export const CREATE_INSTITUTE = "CREATE_INSTITUTE"
export const CREATE_INSTITUTE_SUCCESS = "CREATE_INSTITUTE_SUCCESS"
export const CREATE_INSTITUTE_FAIL = "CREATE_INSTITUTE_FAIL"

/**
 * Edit INSTITUTE
 */
export const UPDATE_INSTITUTE = "UPDATE_INSTITUTE"
export const UPDATE_INSTITUTE_SUCCESS = "UPDATE_INSTITUTE_SUCCESS"
export const UPDATE_INSTITUTE_FAIL = "UPDATE_INSTITUTE_FAIL"

/**
 * Delete INSTITUTE
 */
export const DELETE_INSTITUTE = "DELETE_INSTITUTE"
export const DELETE_INSTITUTE_SUCCESS = "DELETE_INSTITUTE_SUCCESS"
export const DELETE_INSTITUTE_FAIL = "DELETE_INSTITUTE_FAIL"

export const CLEAR_CREATED_INSTITUTE = "CLEAR_CREATED_INSTITUTE"

export const GET_INSTITUTES_FINANCIAL_YEARS = "GET_INSTITUTES_FINANCIAL_YEARS"
export const GET_INSTITUTES_FINANCIAL_YEARS_SUCCESS =
  "GET_INSTITUTES_FINANCIAL_YEARS_SUCCESS"
export const GET_INSTITUTES_FINANCIAL_YEARS_FAIL =
  "GET_INSTITUTES_FINANCIAL_YEARS_FAIL"

export const CLEAR_MAHALL_ACCOUNTS = "CLEAR_MAHALL_ACCOUNTS"
export const CLEAR_MAHALL_ACCOUNTS_SUCCESS = "CLEAR_MAHALL_ACCOUNTS_SUCCESS"
export const CLEAR_MAHALL_ACCOUNTS_FAIL = "CLEAR_MAHALL_ACCOUNTS_FAIL"
