import React from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label } from "reactstrap"

import {
  updateFamilyMemberVarisangyaAmountByFilter,
  updateFilteredWallets,
  updateSelectedWallets,
} from "store/actions"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const UiModalSelectedVarisangyaAmountUpdate = ({
  show,
  onCloseclick,
  history,
  data,
}) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, language } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
  }))


  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
 

    dispatch(
      updateSelectedWallets(
        data.map(item => item.id),
        history,
        params?.type,
        params?.id,
        values.amount
      )
    )
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal"
                ? "വരിസംഗ്യ തുകകൾ ഒന്നിച്ച് അപ്ഡേറ്റ് ചെയ്യുക"
                : "Bulk Update Varisangya Amounts"}
              <hr />
              <p style={{ fontSize: "14px" }} className="fw-normal">
                {language === "mal"
                  ? "താഴെയുള്ള സമർപ്പിക്കുക ബട്ടണിൽ ക്ലിക്ക് ചെയ്യുന്നതിലൂടെ, ലിസ്‌റ്റ് ചെയ്‌ത എല്ലാ കുടുംബങ്ങൾക്കും വരിസംഗ്യ തുക ചേർക്കപ്പെടുന്നതാണ്."
                  : "By clicking the below Submit Button, you will add varisangya amount for all the listed families."}
              </p>
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col md="5" className="d-flex justify-contents-center">
                  <Label className="mx-2">
                    {language === "mal" ? "തുക" : "Amount"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="amount"
                    type="text"
                    validate={{
                      required: { value: true },
                      min: {
                        value: 0,
                        // errorMessage: `${language === "mal"
                        //   ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                        //   : "Amount must be greater than 0"
                        //   }`,
                      },
                    }}
                  />
                </Col>
              </div>
              <div className="row w-100 justify-content-end">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalSelectedVarisangyaAmountUpdate

UiModalSelectedVarisangyaAmountUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
  data: propTypes.any,
}
