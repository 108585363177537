import React from "react"
import { Row, Col } from "reactstrap"

//Import Images
import logolight from "assets/images/logo/logo_white.png"
import appstore from "../../../../assets/images/appstore.png"
import playstore from "../../../../assets/images/playstore.png"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
// import { useWindowSize } from 'components/Common/useWindowSize'

const FooterLink = () => {
  // const size = useWindowSize()
  return (
    <React.Fragment>
      <Row>
        <Col lg="6">
          <div className="mb-4">
            <img src={logolight} alt="" height="70" />
            {/* <p className="">+91 6235666630</p> */}
          </div>

          <p className="mb-2">
            {new Date().getFullYear()} © Khaf.
            {/* {"   "}from{" "}
            <a href="https://#" target={"_blank"} rel="noreferrer">
              Ideal developers
            </a> */}
          </p>
          <p className="pb-2 m-0">
            {"Let's Make Our Mahall Smarter with Khaf Mahall Application"}
          </p>
          <div className="d-flex">
            <Link to="/privacy-policy" style={{ textDecoration: "none" }}>
              <p className="privacy-color">Privacy Policy</p>
            </Link>
            <span className="mx-3"> | </span>
            <Link to={{ pathname: "/terms", state: { scrollPosition: 10 } }}>
              <p className="privacy-color">Terms & Conditions</p>
            </Link>
            <span className="mx-3"> | </span>
            <Link to={{ pathname: "/terms", state: { scrollPosition: 1631 } }}>
              <p className="privacy-color">Cancellation and Refund Policy</p>
            </Link>
          </div>
        </Col>
        <Col lg={6} className="p-5">
          <p
            className="d-flex align-items-center justify-content-center h6"
            style={{ color: "#95989b" }}
          >
            Download the App Now
          </p>
          <div className="d-flex align-items-center justify-content-center">
            <div className="p-2">
              <div style={{ cursor: "pointer" }}>
                <a href="https://play.google.com/store/apps/details?id=com.osperb.khaf">
                  <img
                    src={playstore}
                    style={{ objectFit: "cover", height: 40 }}
                  />
                </a>
                <a href="https://apps.apple.com/in/app/khaf/id6475667639">
                  <img
                    className=""
                    src={appstore}
                    style={{ objectFit: "cover", height: 40 }}
                  />
                </a>
              </div>
            </div>
          </div>
        </Col>
        {/* {size.width < 1000 ? (
          <Col lg="6">
            <a
              href="tel:+91623566630"
              title="Give me a call"
              className="queries"
            >
              <h2 className="p-3 font-popins text-white" style={{ fontSize: "20px", fontWeight: "bold", color: "#009846" }}>
                <i style={{ color: "#009846", fontSize: "20px" }} className="bx bx-phone-call ms-1 bx-fade-left" />
                {"+91 623566630"}</h2>
            </a>
          </Col>) : (
          <Col lg="6">
            <a
              href="tel:+91623566630"
              title="Give me a call"
              className="queries"
            >
              <div className="mt-5">
                <h2 className="justify-content-center d-flex p-3 font-popins text-white" style={{ fontSize: "30px", fontWeight: "bold", color: "#009846" }}>
                  <i style={{ color: "#009846", fontSize: "30px" }} className="bx bx-phone-call ms-1 bx-fade-left" />
                  {"+91 623566630"}</h2>
              </div>
            </a>
          </Col>
        )} */}
      </Row>
    </React.Fragment>
  )
}

export default FooterLink
