import React, { useEffect, useState, Fragment } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Badge,
  Button,
  Label,
  Input,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import moment from "moment"

//actions
// import UiModalVarisangyaAmountUpdate from "./UiModalVarisangyaAmountUpdate"

import {
  getFamilyMembersCards,
  getMahallTypeLists,
  getFamilyMembersForDownload,
  getCountries,
  getFilteredMembers,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
import { useGetSession, useSetSession } from "hooks/useHandleSessions"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import UiModalVarisangyaAmountUpdate from "./UiModalVarisangyaAmountUpdate"
import MyPaginationNew from "components/Common/MyPagination2"
import UiModalSelectedVarisangyaAmountUpdate from "./UiModalSelectedVarisangyaAmountUpdate"

const FamilyMemberWalletLists = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  // const [limit, setLimit] = useState(10)
  const [isFilterListOpen, setIsFilterListOpen] = useState(false)
  const [modalDownload] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [modalVarisangyaAmountUpdate, setModalVarisangyaAmountUpdate] =
    useState(false)
  const [modalVarisangyaAmountUpdateData, setModalVarisangyaAmountUpdateData] =
    useState()
  const [modalSelectedVarisangyaAmountUpdate, setModalSelectedVarisangyaAmountUpdate] =
    useState(false)
  const params = useParams()


  const {
    familyMembers,
    loading,
    language,
    userDetail,
    mahallTypeLists,
    // mahallId,
    // familyMembersforDownload,
    // familyMembersforDownloadLoading,
    allCountries,
  } = useSelector(state => ({
    familyMembers: state.VarisangyaWallets.filteredMembers,
    loading: state.VarisangyaWallets.loading,
    familyMembersforDownload: state.FamilyMembers.familyMembersforDownload,
    familyMembersforDownloadLoading:
      state.FamilyMembers.familyMembersforDownloadLoading,
    familyMembersCards: state.FamilyMembers.familyMembersCards,
    language: state.Layout.language,
    userDetail: state.Users.userDetail,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
    mahallId: state.Users.userDetail?.mahall?.id,
    allCountries: state.Countries.countries?.results,
  }))

  const pageSize_value = useGetSession("pageSize_value")

  const [pageSize, setPageSize] = useState(pageSize_value || 10)
  const [filterListArea, setFilterListArea] = useState([])

  const [searchTextCountries] = useState("")

  // console.log(familyMembersforDownload);

  //pages
  const totalPages = Math.ceil(familyMembers?.count / pageSize)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const handlePremiumLink = () => {
    if (["premium", "standard"].includes(userDetail?.mahall?.version)) {
      return "!#"
    } else {
      return "/premium"
    }
  }

  const sort_value_member = useGetSession("sort_value_member")

  const [sort, setSort] = useState(
    sort_value_member ? sort_value_member : "-custom_id"
  )

  const sortData = [
    {
      heading: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
      id: "mahall_custom_id",
    },
    {
      heading: `${language === "mal" ? "തിയതി" : "Date"}`,
      id: "-custom_id",
    },
  ]

  const filterData = {
    filter: filterListArea,
    sort: [sort],
    search: searchText,
    varisankya_id: params?.id,
  }

  useEffect(() => {
    if (
      userDetail &&
      ["premium", "standard"].includes(userDetail?.mahall?.version) &&
      modalDownload
    ) {
      dispatch(getFamilyMembersForDownload(filterData))
    }
  }, [dispatch, sort, searchText, filterListArea, modalDownload])

  useEffect(() => {
    dispatch(
      getFilteredMembers(
        params?.id,
        searchText,
        pageSize,
        pageSend(),
        filterData,
        params?.type
      )
    )
    params?.type
  }, [
    dispatch,
    page,
    params?.id,
    pageSize,
    searchText,
    filterListArea,
    params?.type,
  ])

  // useEffect(() => {
  //   if (filterData) {
  //     setPage(1)
  //   }
  // }, [page, filterData])

  useEffect(() => {
    dispatch(getFamilyMembersCards())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCountries(searchTextCountries))
  }, [dispatch, searchTextCountries])

  useEffect(() => {
    if (userDetail?.mahall?.id) {
      dispatch(getMahallTypeLists(userDetail?.mahall?.id))
    }
  }, [dispatch, userDetail])

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    if (dateString) {
      const startDate = moment(today)
      const timeEnd = moment(birthDate)
      const diff = startDate.diff(timeEnd)
      var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
      // console.log("diff:", diff / (365 * 24 * 60 * 60 * 1000))
      // console.log("age: ", age)
      if (age < 0) age = 0
      return age
    } else {
      return 0
    }
  }

  function getDateFromAge(age) {
    var today = new Date()
    var birthDate = moment(today)
    birthDate = birthDate.subtract(age, "years")
    birthDate = birthDate.format("YYYY-MM-DD")
    return birthDate
  }

  const columns = [
    // {
    //   dataField: "no",
    //   text: `${language === "mal" ? "നം." : "No."}`,
    // },
    // {
    //   dataField: "custom_id",
    //   text: `${language === "mal" ? "സിസ്റ്റം ഐഡി" : "System ID"}`,
    // },
    {
      dataField: "mahall_custom_id",
      text: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
    },
    {
      dataField: "name",
      text: `${language === "mal" ? "പേര്" : "Name"}`,
      sort: true,
    },
    {
      dataField: "family_name_title",
      text: `${language === "mal" ? "വീട്ടുപേര്" : "Family Name"}`,
    },
    {
      dataField: "ageGender",
      text: `${language === "mal" ? "പ്രായം / ലിംഗം" : "Age / Gender"}`,
    },
    // {
    //   dataField: "blood_group.english",
    //   text: `${language === "mal" ? "രക്ത ഗ്രൂപ്പ്" : "Blood Group"}`,
    // },
    // {
    //   dataField: "healthStatus",
    //   text: `${language === "mal" ? "ആരോഗ്യ സ്ഥിതി" : "Health Status"}`,
    // },
    {
      dataField: "amount",
      text: `${language === "mal" ? "വരിസംഗ്യ തുക" : "Varisankya Amount"}`,
    },
    {
      dataField: "phone",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "varisankya_wallet",
      text: `${language === "mal" ? "ബാലൻസ്" : "Balance"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "" : ""}`,
    },
  ]

  const handleRoute = item => {
    history.push({
      pathname: `/varisangya-wallet/details/${params?.type}/${item?.id}`,
      state: { walletId: item?.varisankya_wallet_details?.wallet_id },
    })
  }

  const familyMembersData = map(familyMembers?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * pageSize + index + 1,
    name: (
      <>
        <p>
          {item?.full_name}

          <span className="text-danger">{` ${!item?.is_alive
            ? language === "mal"
              ? `( നിര്യാതനായി)`
              : `( Late)`
            : ""
            }`}</span>
        </p>
      </>
    ),
    family_name_title: (
      <p>
        {item?.family_name}
        {item?.family_head_name && (
          <span>{`${item?.family_name} (${item?.family_head_name})`}</span>
        )}
      </p>
    ),
    ageGender: `${getAge(item?.dob)} / ${item?.gender && item?.gender != null ? item?.gender : ""
      }`,
    healthStatus: (
      <>
        {item?.health_condition?.map((status, key) => {
          return (
            <Fragment key={key}>
              <Badge
                className={
                  "mx-1 font-size-12 badge-soft-" +
                  `${status.english == "Patient"
                    ? "danger"
                    : status.english == "Satisfied"
                      ? "success"
                      : status.english == "Disabled"
                        ? "warning"
                        : ""
                  }`
                }
                color="black"
                pill
              >
                {language === "mal"
                  ? status.malayalam
                    ? status.malayalam
                    : status.english
                  : status.english}
              </Badge>
            </Fragment>
          )
        })}
      </>
    ),
    amount: <p>{item?.varisankya_wallet_details?.amount || 0}</p>,
    varisankya_wallet: (
      <p
        className={
          item?.varisankya_wallet_details?.balance == 0
            ? "text-primary"
            : item?.varisankya_wallet_details?.balance > 0
              ? "text-success"
              : "text-danger"
        }
      >
        {item?.varisankya_wallet_details?.balance || 0}
      </p>
    ),

    action: (
      <>
        {item?.varisankya_wallet_details?.wallet_id ? (
          <div
            style={{
              cursor: "pointer",
              color: "blue",
            }}
            onClick={() => handleRoute(item)}
          >
            {language === "mal" ? "കാണുക" : "View"}
          </div>
        ) : (
          <p className="text-danger">No Wallet</p>
        )}
      </>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedRows.map(row => row.id), // Pre-select rows based on selectedRows state

    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRows([...selectedRows, { "id": row.id, "full_name": row.full_name, "family_name": row.family_name,"mahall_custom_id": row.mahall_custom_id }]);
      } else {
        setSelectedRows(selectedRows.filter(selected => selected.id !== row.id));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelectedRows([...selectedRows, ...rows.map(row => ({ "id": row.id, "full_name": row.full_name, "family_name": row.family_name,"mahall_custom_id": row.mahall_custom_id }))]);
      } else {
        setSelectedRows(selectedRows.filter(selected => !rows.some(row => row.id === selected.id)));
      }
    },
  };

  const debounceMahallFamilyMemberSearch = debounce(
    value => setSearchText(value),
    600
  )

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  // //multi-list
  // const [genders, setGenders] = useState([])
  // const [selectedMultiGender, setSelectedMultiGender] = useState(null)
  const [relations, setRelations] = useState([])
  const [selectedMultiRelation, setSelectedMultiRelation] = useState(null)
  const [idCardTypes, setIdCardTypes] = useState([])
  const [selectedMultiIdCardType, setSelectedMultiIdCardType] = useState(null)
  const [countries, setCountries] = useState([])
  const [selectedMultiCountry, setSelectedMultiCountry] = useState(null)
  const [maritalStatuses, setMaritalStatuses] = useState([])
  const [selectedMultiMaritalStatus, setSelectedMultiMaritalStatus] =
    useState(null)
  const [unmarriedReasons, setUnmarriedReasons] = useState([])
  const [selectedMultiUnmarriedReason, setSelectedMultiUnmarriedReason] =
    useState(null)
  // const [divorceReasons, setDivorceReasons] = useState([])
  // const [selectedMultiDivorceReason, setSelectedMultiDivorceReason] =
  //   useState(null)
  const [bloodGroups, setBloodGroups] = useState([])
  const [selectedMultiBloodGroup, setSelectedMultiBloodGroup] = useState(null)
  const [healthConditions, setHealthConditions] = useState([])
  const [selectedMultiHealthCondition, setSelectedMultiHealthCondition] =
    useState(null)
  const [diseaseTypes, setDiseaseTypes] = useState([])
  const [selectedMultiDiseaseType, setSelectedMultiDiseaseType] = useState(null)
  const [diseaseLevels, setDiseaseLevels] = useState([])
  const [selectedMultiDiseaseLevel, setSelectedMultiDiseaseLevel] =
    useState(null)
  const [disabilityTypes, setDisabilityTypes] = useState([])
  const [selectedMultiDisabilityType, setSelectedMultiDisabilityType] =
    useState(null)
  const [socialActivities, setSocialActivities] = useState([])
  const [selectedMultiSocialActivity, setSelectedMultiSocialActivity] =
    useState(null)
  const [badHabits, setBadHabits] = useState([])
  const [selectedMultiBadHabit, setSelectedMultiBadHabit] = useState(null)
  const [islamicEducations, setIslamicEducations] = useState([])
  const [selectedMultiIslamicEducation, setSelectedMultiIslamicEducation] =
    useState(null)
  const [formalEducations, setFormalEducations] = useState([])
  const [selectedMultiFormalEducation, setSelectedMultiFormalEducation] =
    useState(null)
  const [notStudyingReasons, setNotStudyingReasons] = useState([])
  const [selectedMultiNotStudyingReason, setSelectedMultiNotStudyingReason] =
    useState(null)
  const [notWorkingReasons, setNotWorkingReasons] = useState([])
  const [selectedMultiNotWorkingReason, setSelectedMultiNotWorkingReason] =
    useState(null)
  const [varisangyaGrades, setVarisangyaGrades] = useState([])
  const [selectedMultiVarisangyaGrade, setSelectedMultiVarisangyaGrade] =
    useState(null)

  const [ages] = useState([])
  const [selectedMultiAge] = useState(null)
  const optionGroupType = type => {
    const optionGroup = mahallTypeLists?.[`${type}`]?.map(item => {
      return {
        value: item.id,
        label: `${language === "mal" ? item?.malayalam : item?.english}`,
      }
    })
    return optionGroup
  }

  function handleMultiType(typeData, setSelectedMultiType, setType) {
    setSelectedMultiType(typeData)
    const arrangedTypes = typeData.map(a => a.value)
    setType(arrangedTypes)
  }

  const mahallTypeListFilterItem = (
    type,
    mal,
    en,
    selectedMultiType,
    setSelectedMultiType,
    setTypes,
    colSize
  ) => {
    return (
      mahallTypeLists?.[`${type}`] &&
      mahallTypeLists?.[`${type}`]?.length > 0 && (
        <Col md={colSize ? colSize : "3"} className="mb-3">
          <Label>{language === "mal" ? mal : en}</Label>
          <div className="mb-3">
            <Select
              value={selectedMultiType}
              isMulti={true}
              onChange={e => {
                handleMultiType(e, setSelectedMultiType, setTypes)
              }}
              options={optionGroupType(type)}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
      )
    )
  }

  const optionGroupCountry = () => {
    const optionGroup = allCountries?.map(item => {
      return {
        value: item.id,
        label: `${language === "mal"
          ? item?.country_malayalam_name
          : item?.country_english_name
          }`,
      }
    })
    return optionGroup
  }

  function handleMultiTypeCountry(
    typeData,
    setSelectedMultiCountry,
    setCountry
  ) {
    setSelectedMultiCountry(typeData)
    const arrangedTypes = typeData.map(a => a.value)
    setCountry(arrangedTypes)
  }

  const countryListFilterItem = (
    mal,
    en,
    selectedMultiCountry,
    setSelectedMultiCountry,
    setCountries,
    colSize
  ) => {
    return (
      allCountries &&
      allCountries?.length > 0 && (
        <Col md={colSize ? colSize : "3"} className="mb-3">
          <Label>{language === "mal" ? mal : en}</Label>
          <div className="mb-3">
            <Select
              value={selectedMultiCountry}
              isMulti={true}
              onChange={e => {
                handleMultiTypeCountry(e, setSelectedMultiCountry, setCountries)
              }}
              options={optionGroupCountry()}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
      )
    )
  }

  const setOfNumbers = count => {
    return Array.from({ length: count }, (v, i) => i)
  }

  // const optionGroupAge = () => {
  //   const optionGroup = setOfNumbers(200).map(item => {
  //     return {
  //       value: item,
  //       label: item,
  //     }
  //   })
  //   return optionGroup
  // }

  // function handleMultiAge(e) {
  //   setSelectedMultiAge(e)
  //   const arrangedAges = e.map(a => a.value)
  //   setAges(arrangedAges)
  // }

  const handleFilterFind = values => {
    setPage(1)
    const filterList = []
    const filterItems = [
      {
        field: "relation_choice_id",
        value: relations,
      },
      {
        field: "id_card_choice_id",
        value: idCardTypes,
      },
      {
        field: "blood_group_choice_id",
        value: bloodGroups,
      },
      {
        field: "marital_status_choice_id",
        value: maritalStatuses,
      },
      {
        field: "unmarried_reason_choice_id",
        value: unmarriedReasons,
      },
      {
        field: "health_condition_choice_id_array",
        value: healthConditions,
      },
      {
        field: "disease_type_choice_id_array",
        value: diseaseTypes,
      },
      {
        field: "disease_level_choice_id",
        value: diseaseLevels,
      },
      {
        field: "disability_type_choice_id_array",
        value: disabilityTypes,
      },
      {
        field: "social_activity_type_id_array",
        value: socialActivities,
      },
      {
        field: "bad_habits_choice_id_array",
        value: badHabits,
      },
      {
        field: "educational_qualification_islamic_choice_id",
        value: islamicEducations,
      },
      {
        field: "educational_qualification_formal_choice_id",
        value: formalEducations,
      },
      {
        field: "not_studying_reason_choice_id ",
        value: notStudyingReasons,
      },
      {
        field: "not_working_reason_choice_id",
        value: notWorkingReasons,
      },
      {
        field: "present_country",
        value: countries,
      },
      {
        field: "family_member_varisankya_grade_choice_id",
        value: varisangyaGrades,
      },
    ]

    for (const filterItem of filterItems) {
      if (filterItem.value.length > 0) {
        filterList.push(filterItem)
      }
    }

    if (values.gender && values.gender !== "none")
      filterList.push({ field: "gender", value: [values.gender] })
    if (
      values.is_abroad &&
      values.is_abroad !== "none" &&
      values.is_abroad !== null
    )
      filterList.push({
        field: "is_abroad",
        value: [
          values.is_abroad === "true"
            ? true
            : values.is_abroad === "false"
              ? false
              : null,
        ],
      })
    if (
      values.ready_to_donate &&
      values.ready_to_donate !== "none" &&
      values.ready_to_donate !== null
    )
      filterList.push({
        field: "ready_to_donate",
        value: [
          values.ready_to_donate === "true"
            ? true
            : values.ready_to_donate === "false"
              ? false
              : null,
        ],
      })
    if (
      values.is_student &&
      values.is_student !== "none" &&
      values.is_student !== null
    )
      filterList.push({
        field: "is_student",
        value: [
          values.is_student === "true"
            ? true
            : values.is_student === "false"
              ? false
              : null,
        ],
      })
    if (
      values.is_working &&
      values.is_working !== "none" &&
      values.is_working !== null
    )
      filterList.push({
        field: "is_working",
        value: [
          values.is_working === "true"
            ? true
            : values.is_working === "false"
              ? false
              : null,
        ],
      })
    if (
      values.is_orphan &&
      values.is_orphan !== "none" &&
      values.is_orphan !== null
    )
      filterList.push({
        field: "is_orphan",
        value: [
          values.is_orphan === "true"
            ? true
            : values.is_orphan === "false"
              ? false
              : null,
        ],
      })

    if (
      values.is_alive &&
      values.is_alive !== "none" &&
      values.is_alive !== null
    )
      filterList.push({
        field: "is_alive",
        value: [
          values.is_alive === "true"
            ? true
            : values.is_alive === "false"
              ? false
              : null,
        ],
      })

    if (
      values.is_family_head &&
      values.is_family_head !== "none" &&
      values.is_family_head !== null
    )
      filterList.push({
        field: "is_family_head",
        value: [
          values.is_family_head === "true"
            ? true
            : values.is_family_head === "false"
              ? false
              : null,
        ],
      })

    if (
      values.age_from &&
      values.age_to &&
      values.age_from !== "none" &&
      values.age_from !== null &&
      values.age_to !== "none" &&
      values.age_to !== null
    ) {
      filterList.push({
        field: "dob",
        value: {
          from: getDateFromAge(parseInt(values.age_to) + 1),
          to: getDateFromAge(parseInt(values.age_from)),
        },
      })
    }
    setFilterListArea(filterList)
    setModalVarisangyaAmountUpdateData(filterList)
    // setModalVarisangyaAmountUpdate(true)
  }

  // const handleFilterFindModal = (values) => {

  //   const filterList = [];
  //   const filterItems = [
  //     {
  //       "field": "relation_choice_id",
  //       "value": relations
  //     },
  //     {
  //       "field": "id_card_choice_id",
  //       "value": idCardTypes
  //     },
  //     {
  //       "field": "blood_group_choice_id",
  //       "value": bloodGroups
  //     },
  //     {
  //       "field": "marital_status_choice_id",
  //       "value": maritalStatuses
  //     },
  //     {
  //       "field": "unmarried_reason_choice_id",
  //       "value": unmarriedReasons
  //     },
  //     {
  //       "field": "health_condition_choice_id_array",
  //       "value": healthConditions
  //     },
  //     {
  //       "field": "disease_type_choice_id_array",
  //       "value": diseaseTypes
  //     },
  //     {
  //       "field": "disease_level_choice_id",
  //       "value": diseaseLevels
  //     },
  //     {
  //       "field": "disability_type_choice_id_array",
  //       "value": disabilityTypes
  //     },
  //     {
  //       "field": "social_activity_type_id_array",
  //       "value": socialActivities
  //     },
  //     {
  //       "field": "bad_habits_choice_id_array",
  //       "value": badHabits
  //     },
  //     {
  //       "field": "educational_qualification_islamic_choice_id",
  //       "value": islamicEducations
  //     },
  //     {
  //       "field": "educational_qualification_formal_choice_id",
  //       "value": formalEducations
  //     },
  //     {
  //       "field": "not_studying_reason_choice_id ",
  //       "value": notStudyingReasons
  //     },
  //     {
  //       "field": "not_working_reason_choice_id",
  //       "value": notWorkingReasons
  //     },
  //     {
  //       "field": "present_country",
  //       "value": countries
  //     },
  //   ]

  //   for (const filterItem of filterItems) {
  //     if (filterItem.value.length > 0) {
  //       filterList.push(filterItem)
  //     }
  //   }

  //   if (values.gender && values.gender !== 'none') filterList.push({ "field": "gender", "value": [values.gender] });
  //   if (values.is_abroad && values.is_abroad !== 'none' && values.is_abroad !== null) filterList.push({ "field": "is_abroad", "value": [values.is_abroad === 'true' ? true : values.is_abroad === 'false' ? false : null] });
  //   if (values.ready_to_donate && values.ready_to_donate !== 'none' && values.ready_to_donate !== null) filterList.push({ "field": "ready_to_donate", "value": [values.ready_to_donate === 'true' ? true : values.ready_to_donate === 'false' ? false : null] });
  //   if (values.is_student && values.is_student !== 'none' && values.is_student !== null) filterList.push({ "field": "is_student", "value": [values.is_student === 'true' ? true : values.is_student === 'false' ? false : null] });
  //   if (values.is_working && values.is_working !== 'none' && values.is_working !== null) filterList.push({ "field": "is_working", "value": [values.is_working === 'true' ? true : values.is_working === 'false' ? false : null] });
  //   if (values.is_orphan && values.is_orphan !== 'none' && values.is_orphan !== null) filterList.push({ "field": "is_orphan", "value": [values.is_orphan === 'true' ? true : values.is_orphan === 'false' ? false : null] });

  //   if (values.age_from && values.age_to && values.age_from !== 'none' && values.age_from !== null && values.age_to !== 'none' && values.age_to !== null) {
  //     filterList.push({
  //       "field": "dob",
  //       "value": {
  //         "from": getDateFromAge(parseInt(values.age_to) + 1),
  //         "to": getDateFromAge(parseInt(values.age_from))
  //       }
  //     });
  //   }

  //   setFilterListArea(filterList)
  //   const filterData = { filter: filterListArea }
  //   console.log('filterData: ', filterData);
  //   setModalVarisangyaAmountUpdateData(filterData)
  //   setModalVarisangyaAmountUpdate(true)
  // }

  return (
    <React.Fragment>
      <UiModalVarisangyaAmountUpdate
        show={modalVarisangyaAmountUpdate}
        onCloseclick={() => setModalVarisangyaAmountUpdate(false)}
        history={history}
        data={modalVarisangyaAmountUpdateData}
      />
      <UiModalSelectedVarisangyaAmountUpdate
        show={modalSelectedVarisangyaAmountUpdate}
        onCloseclick={() => setModalSelectedVarisangyaAmountUpdate(false)}
        history={history}
        data={selectedRows}
      />
      <Row>
        <Col className="col-12 mt-2 sm:p-0">
          <ToolkitProvider
            keyField="id"
            columns={columns}
            data={familyMembersData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col md="2">
                        {!isFilterListOpen && (
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <form
                                className="app-search d-lg-block"
                                onChange={e =>
                                  debounceMahallFamilyMemberSearch(
                                    e.target.value
                                  )
                                }
                              >
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={
                                      language === "mal"
                                        ? "തിരയുക..."
                                        : "Search..."
                                    }
                                    defaultValue={searchText}
                                  />
                                  <span className="bx bx-search-alt" />
                                </div>
                              </form>
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col>
                        <div
                          className="d-flex mt-2 mb-2"
                          style={{ alignItems: "center" }}
                        >
                          <p>{language === "mal" ? "ക്രമം" : "Sort by"}</p>
                          <div className="mx-2 mb-3">
                            <select
                              value={sort}
                              onChange={e => {
                                setSort(e.target.value)
                                useSetSession(
                                  "sort_value_member",
                                  e.target.value
                                )
                              }}
                              style={{
                                height: "30px",
                                padding: "0 5px",
                                borderColor: "#d0d6db",
                                borderRadius: "3px",
                              }}
                            >
                              {/* <option>
                                {language === "mal"
                                  ? "തിരഞ്ഞെടുക്കുക ..."
                                  : "Choose..."}{" "}
                              </option> */}
                              {sortData.map((item, key) => (
                                <option key={key} value={item.id}>
                                  {item.heading}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="d-flex mt-2 mb-2"
                          style={{ alignItems: "center", width: "100px" }}
                        >
                          <p>{language === "mal" ? "ക്രമം" : "Show"}</p>
                          <div className="mx-2">
                            <div className="mb-3">
                              <Input
                                value={pageSize}
                                onChange={e => {
                                  if (
                                    e.target.value <= 0 ||
                                    e.target.value > 100
                                  ) {
                                    // setPageSize(10)
                                  } else {
                                    setPageSize(e.target.value)
                                    useSetSession(
                                      "pageSize_value",
                                      e.target.value
                                    )
                                  }
                                }}
                                style={{
                                  height: "30px",
                                  padding: "0 5px",
                                  borderColor: "#d0d6db",
                                  borderRadius: "3px",
                                }}
                              ></Input>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        md="4"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div>
                          {userDetail?.mahall?.version === "basic" ? (
                            <Link
                              style={{
                                width: language === "mal" ? "135px" : "50px",
                              }}
                              className="w-md btn btn-primary m-auto me-0 mt-0 d-block"
                              to={handlePremiumLink()}
                            >
                              <i className="bx bx-list-ol font-size-16 align-middle"></i>{" "}
                              {language === "mal" ? "ഫിൽറ്റർ" : "Filter"}
                              <i
                                className="bx bx-crown mx-1"
                                style={{ fontSize: "12px", color: "gold" }}
                              />
                            </Link>
                          ) : (
                            <>
                              <Button
                                style={{
                                  width: language === "mal" ? "135px" : "50px",
                                }}
                                className="w-md btn btn-info m-auto me-0 mt-0 d-block bg-khaf-blue"
                                onClick={() =>
                                  setIsFilterListOpen(!isFilterListOpen)
                                }
                              >
                                {!isFilterListOpen ? (
                                  <>
                                    <i className="bx bx-list-ol font-size-16 align-middle"></i>
                                    {language === "mal" ? "ഫിൽറ്റർ" : "Filter"}
                                  </>
                                ) : (
                                  `${language === "mal" ? "അടയ്ക്കുക" : "Close"
                                  }`
                                )}
                              </Button>
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                    {isFilterListOpen ? (
                      <>
                        {/* https://api.dev.khaf.in/api/v1/filter/family-member-filter/?page_size=25 */}
                        <AvForm
                          onValidSubmit={(e, v) => {
                            handleFilterFind(v)
                          }}
                        >
                          <Row>
                            <hr />
                            {/* gender  */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal" ? "ലിംഗം" : "Gender"}
                                </Label>
                                <AvField type="select" name="gender">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      english: "Male",
                                      malayalam: "പുരുഷൻ",
                                      id: "male",
                                    },
                                    {
                                      english: "Female",
                                      malayalam: "സ്ത്രീ",
                                      id: "female",
                                    },
                                    {
                                      english: "Others",
                                      malayalam: "മറ്റുള്ളവ",
                                      id: "others",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }
                            {/* Age */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal" ? "വയസ്സ്" : "Age"}
                                </Label>
                                <Col className=" d-flex justify-content-space-between">
                                  <div className="col-md-6">
                                    <AvField
                                      type="select"
                                      name="age_from"
                                      helpMessage={
                                        language === "mal" ? "മുതൽ" : "From"
                                      }
                                    >
                                      <option>{"none"}</option>
                                      {setOfNumbers(100)?.map((item, key) => (
                                        <option key={key} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </AvField>
                                  </div>

                                  <div className="col-md-6 mx-1">
                                    <AvField
                                      type="select"
                                      name="age_to"
                                      helpMessage={
                                        language === "mal" ? "വരെ" : "To"
                                      }
                                    >
                                      <option>{"none"}</option>
                                      {setOfNumbers(100)?.map((item, key) => (
                                        <option key={key} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </AvField>
                                  </div>
                                </Col>
                              </Col>
                            }
                            {/* is_family_head  */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "കുടുംബനാഥനാണോ?"
                                    : "Is Family Head?"}
                                </Label>
                                <AvField type="select" name="is_family_head">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "അതെ",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "അല്ല ",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }

                            {mahallTypeListFilterItem(
                              "relation",
                              "കുടുംബനാഥനുമായുള്ള ബന്ധം",
                              "Relation",
                              selectedMultiRelation,
                              setSelectedMultiRelation,
                              setRelations,
                              2
                            )}
                            {mahallTypeListFilterItem(
                              "id_card",
                              "ഐഡി കാർഡ് തരം",
                              "Id Card Type",
                              selectedMultiIdCardType,
                              setSelectedMultiIdCardType,
                              setIdCardTypes,
                              2
                            )}
                            {/* is_abroad */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "വിദേശത്താണോ?"
                                    : "Is Abroad?"}
                                </Label>
                                <AvField type="select" name="is_abroad">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "അതെ",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "അല്ല",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }

                            {mahallTypeListFilterItem(
                              "blood_group",
                              "രക്ത ഗ്രൂപ്പ്",
                              "Blood Group",
                              selectedMultiBloodGroup,
                              setSelectedMultiBloodGroup,
                              setBloodGroups
                            )}
                            {/* ready_to_donate */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "രക്തദാനത്തിന് തയ്യാറാണോ?"
                                    : "Ready To Donate?"}
                                </Label>
                                <AvField type="select" name="ready_to_donate">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "അതെ",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "അല്ല",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }
                            {mahallTypeListFilterItem(
                              "marital_status",
                              "വൈവാഹിക നില",
                              "Marital Status",
                              selectedMultiMaritalStatus,
                              setSelectedMultiMaritalStatus,
                              setMaritalStatuses
                            )}
                            {mahallTypeListFilterItem(
                              "unmarried_reason",
                              "അവിവാഹിത/ൻ കാരണം",
                              "Unmarried Reason",
                              selectedMultiUnmarriedReason,
                              setSelectedMultiUnmarriedReason,
                              setUnmarriedReasons
                            )}

                            {mahallTypeListFilterItem(
                              "health_condition",
                              "ആരോഗ്യസ്ഥിതി",
                              "Health Condition",
                              selectedMultiHealthCondition,
                              setSelectedMultiHealthCondition,
                              setHealthConditions,
                              2
                            )}
                            {mahallTypeListFilterItem(
                              "disease_type",
                              "രോഗം",
                              "Disease",
                              selectedMultiDiseaseType,
                              setSelectedMultiDiseaseType,
                              setDiseaseTypes
                            )}
                            {mahallTypeListFilterItem(
                              "disease_level",
                              "രോഗ നില",
                              "Disease Level",
                              selectedMultiDiseaseLevel,
                              setSelectedMultiDiseaseLevel,
                              setDiseaseLevels,
                              2
                            )}
                            {mahallTypeListFilterItem(
                              "disability_type",
                              "വൈകല്യ തരം",
                              "Disability Type",
                              selectedMultiDisabilityType,
                              setSelectedMultiDisabilityType,
                              setDisabilityTypes
                            )}
                            {mahallTypeListFilterItem(
                              "social_activity_type_id",
                              "സാമൂഹിക പ്രവർത്തനങ്ങൾ",
                              "Social Activities",
                              selectedMultiSocialActivity,
                              setSelectedMultiSocialActivity,
                              setSocialActivities
                            )}
                            {mahallTypeListFilterItem(
                              "bad_habits",
                              "മോശം ശീലങ്ങൾ",
                              "Bad Habits",
                              selectedMultiBadHabit,
                              setSelectedMultiBadHabit,
                              setBadHabits,
                              2
                            )}

                            {mahallTypeListFilterItem(
                              "educational_qualification_islamic",
                              "മത വിദ്യാഭ്യാസം",
                              "Islamic Education",
                              selectedMultiIslamicEducation,
                              setSelectedMultiIslamicEducation,
                              setIslamicEducations
                            )}
                            {mahallTypeListFilterItem(
                              "educational_qualification_formal",
                              "ഭൗതിക വിദ്യാഭ്യാസം",
                              "Formal Education",
                              selectedMultiFormalEducation,
                              setSelectedMultiFormalEducation,
                              setFormalEducations
                            )}

                            {/* is_student */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "വിദ്യാർത്ഥിയാണോ?"
                                    : "Is Student?"}
                                </Label>
                                <AvField type="select" name="is_student">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "അതെ",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "അല്ല",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }
                            {mahallTypeListFilterItem(
                              "not_studying_reason",
                              "പഠിക്കാത്തതിന്റെ കാരണം",
                              "Not Studying Reason",
                              selectedMultiNotStudyingReason,
                              setSelectedMultiNotStudyingReason,
                              setNotStudyingReasons
                            )}

                            {/* is_working */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "ജോലി ചെയ്യുന്നുണ്ടോ?"
                                    : "Is Working?"}
                                </Label>
                                <AvField type="select" name="is_working">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "അതെ",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "അല്ല",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }
                            {mahallTypeListFilterItem(
                              "not_working_reason",
                              "ജോലി ചെയ്യാത്തതിന്റെ കാരണം",
                              "Not Working Reason",
                              selectedMultiNotWorkingReason,
                              setSelectedMultiNotWorkingReason,
                              setNotWorkingReasons
                            )}

                            <Col md="2" className="mb-3">
                              <Label>
                                {language === "mal"
                                  ? "അനാഥനാണോ?"
                                  : "Is Orphan?"}
                              </Label>
                              <AvField type="select" name="is_orphan">
                                <option>{"none"}</option>
                                {[
                                  {
                                    id: true,
                                    malayalam: "അതെ",
                                    english: "Yes",
                                  },
                                  {
                                    id: false,
                                    malayalam: "അല്ല",
                                    english: "No",
                                  },
                                ]?.map((item, key) => (
                                  <option key={key} value={item.id}>
                                    {language === "mal"
                                      ? item?.malayalam
                                      : item?.english}
                                  </option>
                                ))}
                              </AvField>
                            </Col>

                            {/* {countryListFilterItem('not_working_reason', "ജോലി ചെയ്യാത്തതിന്റെ കാരണം", "Not Working Reason", selectedMultiNotWorkingReason, setSelectedMultiNotWorkingReason, setNotWorkingReasons)} */}

                            {countryListFilterItem(
                              "രാജ്യം",
                              "Country",
                              selectedMultiCountry,
                              setSelectedMultiCountry,
                              setCountries,
                              3
                            )}

                            <Col md="2" className="mb-3">
                              <Label>
                                {language === "mal"
                                  ? "ജീവിച്ചിരിപ്പുണ്ടോ?"
                                  : "Is Alive?"}
                              </Label>
                              <AvField type="select" name="is_alive">
                                <option>{"none"}</option>
                                {[
                                  {
                                    id: true,
                                    malayalam: "അതെ",
                                    english: "Yes",
                                  },
                                  {
                                    id: false,
                                    malayalam: "അല്ല",
                                    english: "No",
                                  },
                                ]?.map((item, key) => (
                                  <option key={key} value={item.id}>
                                    {language === "mal"
                                      ? item?.malayalam
                                      : item?.english}
                                  </option>
                                ))}
                              </AvField>
                            </Col>

                            {mahallTypeListFilterItem(
                              "varisankya_grade",
                              "വരിസംഖ്യ ഗ്രേഡ്",
                              "Varisangya Grade",
                              selectedMultiVarisangyaGrade,
                              setSelectedMultiVarisangyaGrade,
                              setVarisangyaGrades,
                              2
                            )}

                            <Col className="mb-3 d-flex align-items-end justify-content-end">
                              {/* <Button
                                onClick={handleFilterFindModal}
                                type="button"
                                color="info"
                                className="me-3">
                                {language === "mal" ? "വരിസംഗ്യ തുക അപ്ഡേറ്റ് ചെയ്യുക" : "Bulk Update Varisangya Amount"}
                              </Button> */}
                              <Button
                                onClick={() =>
                                  setModalSelectedVarisangyaAmountUpdate(true)
                                }
                                type="submit"
                                color="info"
                                className="me-2"
                              >
                                {language === "mal"
                                  ? "തിരഞ്ഞെടുത്തവരുടെ 🗹 വരിസംഗ്യ തുക അപ്ഡേറ്റ് ചെയ്യുക"
                                  : "Update Varisangya Amount for selected 🗹"}
                              </Button>
                              <Button
                                onClick={() =>
                                  setModalVarisangyaAmountUpdate(true)
                                }
                                type="submit"
                                color="info"
                                className="w-xl me-2"
                              >
                                {language === "mal"
                                  ? "വരിസംഗ്യ തുക അപ്ഡേറ്റ് ചെയ്യുക"
                                  : "Bulk Update Varisangya Amount"}
                              </Button>
                              <Button
                                type="submit"
                                color="success"
                                className="w-xl"
                              >
                                {language === "mal" ? "ഫിൽറ്റർ" : "Find"}
                              </Button>
                            </Col>
                          </Row>
                        </AvForm>
                      </>
                    ) : (
                      <></>
                    )}
                  </CardBody>
                </Card>



                {selectedRows?.length > 0 && (
                  <Card>
                    <CardBody>

                      <h5 className="mb-3">
                        {language === "mal"
                          ? "തിരഞ്ഞെടുത്തവരുടെ വിവരങ്ങൾ"
                          : "Selected Rows "}
                        <span className="bg-info rounded-pill badge badge-secondary">{selectedRows.length}</span>
                      </h5>

                      <ul className="list-unstyled d-flex flex-wrap">
                        {selectedRows.map((row, index) => (
                          <li key={index} className="rounded-pill bg-light p-2 me-2 mb-2">
                            {`${row.mahall_custom_id}  |  ${row?.full_name}  |  ${row?.family_name ?? ""}`}
                            <span
                              size="sm"
                              className="rounded-pill text-danger fw-bold cursor-pointer"
                              onClick={() =>
                                setSelectedRows(
                                  selectedRows.filter(
                                    (_, i) => i !== index
                                  )
                                )
                              }
                            > X
                            </span>
                          </li>
                        ))}
                      </ul>

                      <div
                        className="d-flex align-items-end justify-content-end"
                      >
                        <Button
                          onClick={() =>
                            setModalSelectedVarisangyaAmountUpdate(true)
                          }
                          type="submit"
                          color="info"
                          className="me-2"
                        >
                          {language === "mal"
                            ? "തിരഞ്ഞെടുത്തവരുടെ 🗹 വരിസംഗ്യ തുക അപ്ഡേറ്റ് ചെയ്യുക"
                            : "Update Varisangya Amount for selected 🗹"}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                )}


                <Card>
                  <CardBody>
                    {/* <Col md="12" className="mb-3 d-flex align-items-end justify-content-end">
                      <Button
                        onClick={handleFilterFindModal}
                        type="button"
                        color="info"
                        className="me-3">
                        {language === "mal" ? "വരിസംഗ്യ തുക അപ്ഡേറ്റ് ചെയ്യുക" : "Bulk Update Varisangya Amount"}
                      </Button>
                    </Col> */}
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"with-check-box table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPaginationNew
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {familyMembers &&
                      familyMembers?.results &&
                      familyMembers?.results?.length <= 0 && (
                        <p
                          style={{ fontSize: "15px" }}
                          className="text-center text-info"
                        >
                          {language === "mal"
                            ? "അംഗങ്ങൾ ഒന്നും ഇല്ല"
                            : "No Members Yet"}
                        </p>
                      )}
                  </CardBody>
                </Card>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FamilyMemberWalletLists
